import BaseService from "@/services/base-service";
import { TfPerson } from "@/interfaces/api";

class PersonService extends BaseService {
  /**
   * Fetches the persons that match the specified search term
   *
   * @param {string} searchTerm - The search term
   * @returns {Promise<Array<TfPerson>>} A promise with a list of persons
   */
  getPersons(searchTerm: string): Promise<Array<TfPerson>> {
    const endpointController = "Person";
    const endpointRoute = "search";
    const parameterName = "searchTerm";
    const parameterArgument = searchTerm;
    const getPersons = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<Array<TfPerson>>(getPersons);
  }

  /**
   * Fetches the person with the specified lucat id
   *
   * @param {string} lucatId - The person's lucat id
   * @returns {Promise<TfPerson>} A promise with a person
   */
  getPerson(lucatId: string): Promise<TfPerson> {
    const endpointController = "Person";
    const endpointRoute = "lucatId";
    const parameterName = "lucatId";
    const parameterArgument = lucatId;
    const querystring = `${parameterName}=${parameterArgument}`;
    const getPerson = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${querystring}`;
    return this.fetch<TfPerson>(getPerson);
  }
}

export default new PersonService();
