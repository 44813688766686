
import { Options, Vue } from "vue-class-component";
import Table from "@/components/Table.vue";
import TextInput from "@/components/Form/TextInput.vue";
import { SessionStorageKeys } from "@/constants/constants";
import { TfPerson } from "@/interfaces/api.ts";
import PersonService from "@/services/tuition-fees/person-service.ts";
import SessionStorageService from "@/services/sessionstorage-service";

@Options({
  components: {
    TextInput,
    Table,
  },
  data: () => ({
    /*
     * Holds the search term entered in the text input
     */
    searchTerm: null,
    /*
     * Holds the search results
     */
    searchResults: [],
    /*
     * Used in conjunction with the no results message
     */
    searchDone: false,
  }),
  computed: {
    /*
     * Keeps the search button disabled until a search term of at least 2 characters is entered
     */
    searchButtonDisabled(): boolean {
      return this.searchTerm ? this.searchTerm.length < 2 : true;
    },
  },
  methods: {
    /*
     * Executes a search for users based on the search term
     */
    searchUsers(): void {
      this.$store.commit("showSpinner", true);
      this.searchDone = false;

      PersonService.getPersons(this.searchTerm)
        .then((data: Array<TfPerson>) => {
          this.searchDone = true;
          this.searchResults = data.map((person: TfPerson) => {
            return {
              luEduPersonPrimaryId: person.luEduPersonPrimaryId,
              personalId: person.personalId,
              displayName: person.displayName,
              email: person.email,
              links: null,
            };
          });
        })
        .catch((e: Error) => {
          console.error(e);
        })
        .finally(() => {
          SessionStorageService.setItem(
            SessionStorageKeys.MirrorUserSearchTerm,
            this.searchTerm
          );
          this.$store.commit("showSpinner", false);
        });
    },
    /*
     * Places focus in the text input
     */
    focusTextInput(): void {
      this.$refs.searchInput?.focus();
    },
    /*
     * Checks if there is a search  term in session storage and if so, executes a search
     */
    CheckForSearchQuery(): void {
      this.searchTerm = SessionStorageService.getItem(
        SessionStorageKeys.MirrorUserSearchTerm
      );

      if (this.searchTerm) {
        this.searchUsers();
      }
    },
    /*
     * Sets the session storage keys MirroringUser & CurrentMirroredUser and navigates to the tuition fee home view
     */
    onMirrorClicked(
      personalId: string,
      displayName: string,
      lucatId: string
    ): void {
      SessionStorageService.setItem(SessionStorageKeys.IsMirroringUser, true);
      SessionStorageService.setItem(SessionStorageKeys.MirroredUser, {
        personalId: personalId,
        displayName: displayName,
        luEduPersonPrimaryId: lucatId,
      });

      this.$router.push({
        name: "TuitionFeeHome",
      });
    },
  },
  mounted(): void {
    this.CheckForSearchQuery();
    this.focusTextInput();
  },
})
export default class SearchUser extends Vue {}
